import * as React from "react"
import Iframe from "react-iframe"
import { Center } from "@chakra-ui/react"

export const IframeAdaPools: React.FC = () => {
  return (
    <Center h="500px" color="white">
      <Iframe
        url="https://js.adapools.org/widget.html?pool=07c3280a92a3010a1713e3cb22bfa82624a33061ad20c7b359e7252d"
        width="500"
        height="400"
        id="myId"
        className="myClassname"
        display="block"
        position="relative"
      >
        <a href="https://adapools.org/pool/07c3280a92a3010a1713e3cb22bfa82624a33061ad20c7b359e7252d">
          Detail
        </a>
      </Iframe>
    </Center>
  )
}

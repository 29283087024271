import {
  Box,
  Center,
  chakra,
  Container,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react"
import { CheckIcon } from "@chakra-ui/icons"
import * as React from "react"

// Replace test data with your own
const features = [
  {
    id: 1,
    title: "Lowest Fees",
    text: " Our pool charges the lowest possible fees at 0% margin. ",
  },
  {
    id: 2,
    title: " Unique NFT Awards ",
    text: "We mint artwork NFTs and award them to our delegators. ",
  },
  {
    id: 3,
    title: "Independent Hardware ",
    text: " Uptime is utmost important. Dedicated hardware and monitored" + " 24-7. ",
  },
]

export default function StakeDetails() {
  return (
    <Container maxW={"6xl"} mt={10}>
      <Stack as={Box} textAlign={"center"} spacing={{ base: 8, md: 14 }} py={{ base: 20, md: 36 }}>
        <Heading textAlign={"center"} fontSize={"4xl"} py={10} fontWeight={"bold"}>
          Why stake with us?
        </Heading>

        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          {features.map((feature) => (
            <HStack key={feature.id} align={"top"}>
              <Box color={"green.400"} px={2}>
                <Icon as={CheckIcon} />
              </Box>
              <VStack align={"start"}>
                <Text fontSize="xl" fontWeight={600}>
                  {feature.title}
                </Text>
                <Text textAlign={"left"}>{feature.text}</Text>
              </VStack>
            </HStack>
          ))}
        </SimpleGrid>

        <Text align={"center"}>
          Be automatically entered to win a unique Llama NFT by staking at least 1000₳ with us!
          <br />
          Our stake pool does not operate in the cloud, or on any form of virtualization. Just simple
          <Text as="em"> bare-metal </Text>
          staking in a safe location with reliable power grid with UPS backup and one of the fastest internet
          speeds in the world.
        </Text>
      </Stack>
    </Container>
  )
}

function EpochCard({ ...props }) {
  const { title, text } = props
  return (
    <Box shadow={"md"} border={"1px solid"} rounded={"lg"} w="100%" p={4}>
      <VStack>
        <Heading size="md">{title}</Heading>
        <Text>{text}</Text>
      </VStack>
    </Box>
  )
}

export function WhatIsStaking() {
  return (
    <Box mb={10} maxW="7xl" mx={"auto"} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      <Heading textAlign={"center"} fontSize={"4xl"} py={10} fontWeight={"bold"}>
        What is staking?
      </Heading>
      <Text mb={"1em"} textAlign={"center"}>
        Staking is kind of like banking your money and getting paid interest. Here's how it works.{" "}
      </Text>
      <VStack spacing={{ base: 5, lg: 8 }}>
        <EpochCard
          title={"Epoch 1"}
          text={"Your ₳ become registered with the pool after 1 epoch (=5 days)."}
        />
        <EpochCard title={"Epoch 2"} text={"Your ₳ becomes active and start earning rewards."} />
        <EpochCard title={"Epoch 3"} text={"Your rewards from Epoch 2 are being calculated for payout."} />
        <EpochCard
          title={"Epoch 4"}
          text={
            "Your 1st payout from Epoch 2 are deposited to your account, rewards are" +
            " also paid retroactively."
          }
        />
        <EpochCard
          title={"Epoch 5"}
          text={
            "Your payout from Epoch 3 are deposited to your account, etc..." +
            " and from now on, automatically deposited to your ₳ wallet directly."
          }
        />
      </VStack>
    </Box>
  )
}

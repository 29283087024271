import * as React from "react"
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react"
import Head from "next/head"
import { HomeLayout } from "components/HomeLayout"
import WhyStake, { WhatIsStaking } from "components/StakeDetails"
import splitbee from "@splitbee/web"
import { createIcon } from "@chakra-ui/icons"
import { LinkButton } from "components/LinkButton"
import { IframeAdaPools } from "components/IframeAdaPools"
import { IframeGrafanaStats } from "components/IframeGrafanaStats"

// Splitbee.js
splitbee.init()

// Enable cookie based tracking
splitbee.enableCookie()

splitbee.track("Home")
Home.getLayout = (page: React.ReactNode) => <HomeLayout>{page}</HomeLayout>

export default function Home() {
  return (
    <Box>
      <Head>
        <title>ROAMpool</title>
      </Head>
      <Hero />
      <Center>
        <Text
          maxW={"3xl"}
          fontSize={"3xl"}
          fontFamily={"Caveat"}
          transform={"rotate(-1deg)"}
          m={20}
          align={"center"}
        >
          Our ROAMpool story is like this...
          <br />
          <br />
          We were interested in blockchain decentralization and settled on Cardano. We want to support its
          growth by becoming a pool operator. Cardano is grow quickly, processing more transactions than
          Ethereum!
          <br />
          <br />
          Cardano is on the path to become core infrastructure for a global decentralization movement, an
          upcoming historical period with more individual freedom than ever. A future where we work remotely,
          have decentralized financial services, and decentralized governing bodies.
          <br />
          <br />
          That is our dream of the future. We are the ROAMers in that future. We are ROAMpool (and ROAMverse —
          shhh! Still in the works!).
          <br />
        </Text>
      </Center>
      <WhyStake />

      <WhatIsStaking />
      <Heading textAlign={"center"} fontSize={"4xl"} py={5} fontWeight={"bold"}>
        How is the stake pool doing?
      </Heading>
      <IframeAdaPools />
      {/*<IframeGrafanaStats />*/}
    </Box>
  )
}

export function StakeTutorial() {
  return (
    <Box as="section">
      <Box maxW="xl" mx="auto" px={{ base: "6", lg: "8" }} py={{ base: "16", sm: "20" }} textAlign="center">
        <Heading size="xl" fontWeight="extrabold" letterSpacing="tight">
          Ready to Stake?
        </Heading>
        <Text mt="4" fontSize="lg">
          Watch a 30s video tutorial on how to stake.
        </Text>
        <Button rounded={"md"} mt="8" as="a" href="#" size="lg" colorScheme="blue" fontWeight="bold">
          Watch Stake Video
        </Button>
      </Box>
    </Box>
  )
}

export function Hero() {
  return (
    <Container maxW={"3xl"}>
      <Stack as={Box} textAlign={"center"} spacing={{ base: 8, md: 14 }} py={{ base: 20, md: 36 }}>
        <Heading fontWeight={600} fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }} lineHeight={"110%"}>
          <Text as={"span"} color={"green.400"}>
            ROAM
          </Text>
          pool
        </Heading>
        <Text color={"gray.500"}>A stake pool on the Cardano Blockchain that rewards NFT artwork</Text>
        <Stack direction={"column"} spacing={3} align={"center"} alignSelf={"center"} position={"relative"}>
          <LinkButton
            href="/nfts"
            colorScheme={"green"}
            bg={"green.400"}
            rounded={"full"}
            px={6}
            _hover={{
              bg: "green.500",
            }}
          >
            Show me!
          </LinkButton>
          <Box>
            <Icon
              as={Arrow}
              color={useColorModeValue("gray.800", "gray.300")}
              w={71}
              position={"absolute"}
              right={-71}
              top={"10px"}
            />
            <Text
              fontSize={"lg"}
              fontFamily={"Caveat"}
              position={"absolute"}
              right={"-125px"}
              top={"-15px"}
              transform={"rotate(15deg)"}
            >
              Look, awards!
            </Text>
          </Box>
        </Stack>
        <Flex w={"full"}>
          <LogoImage />
        </Flex>
      </Stack>
    </Container>
  )
}

const Arrow = createIcon({
  displayName: "Arrow",
  viewBox: "0 0 72 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.600904 7.08166C0.764293 6.8879 1.01492 6.79004 1.26654 6.82177C2.83216 7.01918 5.20326 7.24581 7.54543 7.23964C9.92491 7.23338 12.1351 6.98464 13.4704 6.32142C13.84 6.13785 14.2885 6.28805 14.4722 6.65692C14.6559 7.02578 14.5052 7.47362 14.1356 7.6572C12.4625 8.48822 9.94063 8.72541 7.54852 8.7317C5.67514 8.73663 3.79547 8.5985 2.29921 8.44247C2.80955 9.59638 3.50943 10.6396 4.24665 11.7384C4.39435 11.9585 4.54354 12.1809 4.69301 12.4068C5.79543 14.0733 6.88128 15.8995 7.1179 18.2636C7.15893 18.6735 6.85928 19.0393 6.4486 19.0805C6.03792 19.1217 5.67174 18.8227 5.6307 18.4128C5.43271 16.4346 4.52957 14.868 3.4457 13.2296C3.3058 13.0181 3.16221 12.8046 3.01684 12.5885C2.05899 11.1646 1.02372 9.62564 0.457909 7.78069C0.383671 7.53862 0.437515 7.27541 0.600904 7.08166ZM5.52039 10.2248C5.77662 9.90161 6.24663 9.84687 6.57018 10.1025C16.4834 17.9344 29.9158 22.4064 42.0781 21.4773C54.1988 20.5514 65.0339 14.2748 69.9746 0.584299C70.1145 0.196597 70.5427 -0.0046455 70.931 0.134813C71.3193 0.274276 71.5206 0.70162 71.3807 1.08932C66.2105 15.4159 54.8056 22.0014 42.1913 22.965C29.6185 23.9254 15.8207 19.3142 5.64226 11.2727C5.31871 11.0171 5.26415 10.5479 5.52039 10.2248Z"
      fill="currentColor"
    />
  ),
})

export function LogoImage() {
  return (
    <Center flexDir="column">
      <Box
        mt={10}
        w={"full"}
        bg={useColorModeValue("white", "gray.900")}
        boxShadow={"2xl"}
        rounded={"2xl"}
        overflow={"hidden"}
      >
        <Image src="https://roampool.com/roam-1500x500.jpeg" alt="Banner" />
      </Box>
    </Center>
  )
}
